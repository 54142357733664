<script>
import TemplateHeaderTableNew from '@/components/TemplateHeaderTableList'
import { getAction,downloadAction } from '@/command/netTool'
import { localProductsMixin } from '@/mixins/orderProcessing/localProductsMixin'
import Big from 'big.js'

export default {
  name: 'dataReportproductReport',
  data() {
    return {
      records: [],
      totalData: null,
      current: 1,
      total: 1,
      // listUrl: '/reportForm/getTicketScenicOrderPage',
      params: { current: 1, pageSize: 10 },
      paramsValue: { host: '/api' },
    }
  },
  mixins: [localProductsMixin],
  mounted() {
    this.getList()
  },
  methods: {
    getTotal(params) {
      return new Promise((resolve) => {
        // if (this.totalData) {
        //   resolve(this.totalData)
        // } else {
        this.params = {
          ...this.params,
          ...params,
        }
        getAction('/reportForm/getProductOrderTotal', this.params).then((e) => {
          this.totalData = e.data
          resolve(e.data)
        })
        // }
      })
    },
    getSum(list) {
      let orderCount = 0
      let productQuantity = 0
      let refundCount = 0
      let refundAmount = 0
      let salesJsAmount = 0
      let totalAmount = 0
      let feeAmount = 0
      list.forEach((e) => {
        orderCount = parseFloat(new Big(e.orderCount || 0).plus(orderCount))
        productQuantity = parseFloat(new Big(e.productQuantity || 0).plus(productQuantity))
        refundCount = parseFloat(new Big(e.refundCount || 0).plus(refundCount))
        refundAmount = parseFloat(new Big(e.refundAmount || 0).plus(refundAmount))
        salesJsAmount = parseFloat(new Big(e.salesJsAmount || 0).plus(salesJsAmount))
        totalAmount = parseFloat(new Big(e.totalAmount || 0).plus(totalAmount))
        feeAmount = parseFloat(new Big(e.feeAmount || 0).plus(feeAmount))
      })
      return {
        productName: '合计',
        orderCount,
        productQuantity,
        refundAmount,
        refundCount,
        salesJsAmount,
        totalAmount,
        feeAmount,
      }
    },
    getList(params = this.params) {
      this.getTotal(params).then((total) => {
        getAction('/reportForm/getProductOrderPage', params).then((e) => {
          this.records = e.data['records']
          this.params['current'] = e.data['current']
          this.params['pageSize'] = e.data['pageSize']
          this.current = e.data['current']
          this.total = e.data['total']
          if (this.records.length !== 0) {
            // this.records.push(this.getSum(e.data['records']))
            // this.records.push({
            //   orderCount: total.orderCountHj,
            //   totalAmount: total.totalAmountHj,
            //   productQuantity: total.productQuantityHj,
            //   productQuantityCompleted: total.productQuantityCompletedHj,
            //   totalAmountCompleted: total.totalAmountCompletedHj,
            //   productName: '合计',
            //   type: '1',
            //   salesJsAmount: total.salesJsAmountHj,
            //   profitAmount: total.profitAmountHj,
            // })
            this.records.push({ ...total, productName: '总计' })
          }
        })
      })
    },
    getHeader() {
      return [
        // {
        //   type: 'select',
        //   name: '产品类型',
        //   key: 'fromType',
        //   typeData: [
        //     {
        //       name: '景点门票',
        //       value: 12,
        //     },
        //     {
        //       name: '酒店民宿',
        //       value: 13,
        //     },
        //   ],
        // },
        {
          name: '日期',
          type: 'rangePicker',
          keys: ['dateGe', 'dateLe'],
        },
        {
          name: '产品名称',
          type: 'input',
          key: 'productName',
        },
      ]
    },
    getColumns() {
      return [
        {
          title: '出售商品',
          dataIndex: 'productName',
          customRender: (text, records) => {
            return (
              <div class="product-order-item-copy">
                {records.productPic ? <img src={records.productPic.split(',')[0]} /> : ''}
                <div>{text}</div>
              </div>
            )
          },
        },
        {
          title: '购买人',
          dataIndex: 'buyName',
        },
        {
          title: '手机号',
          dataIndex: 'buyPhone',
        },
        {
          title: '下单时间',
          dataIndex: 'createTime',
        },
        {
          title: '数量',
          dataIndex: 'productQuantity',
        },
        {
          title: '订单金额',
          dataIndex: 'totalAmount',
          sorter: (a, b, sortOrder) => {
            if (a.productName == '合计' || a.productName == '总计') {
              if (sortOrder == 'ascend') {
                return 9999999999999
              } else if (sortOrder == 'descend') {
                return -10000
              } else {
                return 99999999999999
              }
            } else {
              return a.totalAmount - b.totalAmount
            }
          },
        },
        {
          title: '退款数量',
          dataIndex: 'refundCount',
        },
        {
          title: '退款金额',
          dataIndex: 'refundAmount',
        },
        {
          title: '手续费',
          dataIndex: 'feeAmount',
          sorter: (a, b, sortOrder) => {
            if (a.createTime == '合计' || a.createTime == '总计') {
              if (sortOrder == 'ascend') {
                return 9999999999999
              } else if (sortOrder == 'descend') {
                return -10000
              } else {
                return 99999999999999
              }
            } else {
              return a.feeAmount - b.feeAmount
            }
          },
        },
        {
          title: '结算金额',
          dataIndex: 'salesJsAmount',
          sorter: (a, b, sortOrder) => {
            if (a.productName == '合计' || a.productName == '总计') {
              if (sortOrder == 'ascend') {
                return 9999999999999
              } else if (sortOrder == 'descend') {
                return -10000
              } else {
                return 99999999999999
              }
            } else {
              return a.salesJsAmount - b.salesJsAmount
            }
          },
        },
        {
          title: '订单状态',
          dataIndex: 'status',
          customRender: (text, records) => {
            const status = { 2: '待发货', 3: '待签收', 4: '待评价', 5: '已完成' }
            return <div>{status[records.status]}</div>
          },
        },
        {
          dataIndex: 'a1',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          typeData: ({ records }) => {
            // @ApiModelProperty(value = "订单来源：12 景点门票 13 酒店民宿")
            if (records.productName == '合计' || records.productName == '总计') {
              return []
            }
            return [
              {
                display: !!records.productId,
                name: '详情',
                onClick: () => {
                  // this.$router.push(`/dataReport/productReportDetail?productId=${records.productId}`)
                  this.onSelectRowLocalProducts(records, true)
                },
              },
            ]
          },
        },
      ]
    },
    onChange(data, filter) {
      this.getList(data)
    },
     getRightButton() {
      return [
        {
          name: '导出Excel',
          type: 'normal2',
          onClick: (data) => {
            console.log(data)
            console.log(this.params)
            const urlParam = { ...this.params }
            delete urlParam.current
            delete urlParam.size
            delete urlParam.pageSize

            let params = ''
            Object.keys(urlParam).map(key=>{
               if (urlParam[key]){
                  params = params ? `${params}&${key}=${urlParam[key]}` : `?${key}=${urlParam[key]}`
               }
            })
 
            downloadAction(
              `/api/reportForm/exportProductReport${params}`,
              '特产报表.xlsx'
            )
          },
        },
      ]
    },
  },
  render() {
    return (
      <TemplateHeaderTableNew
        records={this.records}
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        rightButton={this.getRightButton()}
        onChange={this.onChange}
        button={[]}
        filterRightButtonKey={['refresh', 'setting']}
      />
    )
  },
}
</script>
